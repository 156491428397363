import { lazy } from "react";
import Loadable from "components/Loadable";
import MainLayout from "views/layout/private/Index";

const Dashboard = Loadable(lazy((() => import("views/pages/private/Dashboard"))));
const Support = Loadable(lazy((() => import("views/pages/private/Support/Support"))));
const Settings = Loadable(lazy((() => import("views/pages/private/Settings/Settings"))));
const Merchants = Loadable(lazy(() => import("views/pages/private/Admin/Merchants")));
const Orders = Loadable(lazy(() => import("views/pages/private/Admin/Orders")));
const Profile = Loadable(lazy(() => import("views/pages/private/Profile")));
const ProfileChangePassword = Loadable(lazy(() => import("views/pages/private/Profile/UserChangePassword")));
const EditProfile = Loadable(lazy(() => import("views/pages/private/Profile/UserEditProfile")));

const PrivateRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
        {
            path: "dashboard",
            element: <Dashboard />
        },
        {
            path: "support",
            element: <Support />
        },
        {
            path: "settings",
            element: <Settings />
        },
        {
            path: "merchants",
            element: <Merchants/>
        },
        {
            path: "my-profile",
            element: <Profile/>
        },
        {
            path: "my-profile/change-password",
            element: <ProfileChangePassword/>
        },
        {
            path: "my-profile/update-profile",
            element: <EditProfile/>
        },
        {
            path: "orders",
            element: <Orders/>
        },

    ]
};



export default PrivateRoutes;
