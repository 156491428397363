interface IKeyVal {
    key: string,
    value: string
}

export const getEnv = (key: string): string | null => {
    const env: IKeyVal[] = [
        { key: "DEFAULT_CURRENCY_SYMBOL", value: process.env.REACT_APP_DEFAULT_CURRENCY_SYMBOL as string },
        { key: "API_ENDPOINT", value: process.env.REACT_APP_API_ENDPOINT as string },
        { key: "APP_MEDIA_BEEP", value: process.env.REACT_APP_MEDIA_BEEP as string },
        { key: "APP_KEY", value: process.env.REACT_APP_APP_KEY as string },
        { key: "SIGNATURE_SECRET", value: process.env.REACT_APP_SIGNATURE_SECRET as string },
        { key: "ERS", value: process.env.REACT_APP_ERS_KEY as string },
        { key: "PIM", value: process.env.REACT_APP_PIM_KEY as string },
        { key: "CRM", value: process.env.REACT_APP_CRM_KEY as string },
        { key: "LMS", value: process.env.REACT_APP_LMS_KEY as string },
        { key: "HRIS", value: process.env.REACT_APP_HRIS_KEY as string },
        { key: "GOOGLE_RECAPTCHA", value: process.env.REACT_APP_RECAPTCHA_KEY as string },
        { key: "GOOGLE_MAP_KEY", value: process.env.REACT_APP_GOOGLE_MAP_KEY as string },
    ];

    const result = env.find((x:IKeyVal) => x.key === key);
    return typeof(result) !== "undefined" ? result.value : null;
}